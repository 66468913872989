<template>
  <div class="main">
    <div class="topbar">
      <Tabs v-model="currentTabIndex"
            class="tabs"
            @on-click="onClickTabs">
        <TabPane v-for="item in tabArr"
                 :key="item.name"
                 :label="item.name"></TabPane>
      </Tabs>
      <div class="topbar-search">
        <div class="flex a-center j-between">
          <div class="flex">
            <template v-if="currentTabIndex===2">
              <Select v-model="facilityBigType"
                      placeholder="设施大类"
                      clearable
                      @on-change="onChangeBigType"
                      class="m-r-10"
                      style="width:160px">
                <Option v-for="item in facilityBigTypeArr"
                        :value="item.id"
                        :key="item.id">{{ item.name }}</Option>
              </Select>
              <!-- <Select v-model="facilitySmallType"
                      placeholder="设施小类"
                      clearable
                      :disabled="!facilityBigType"
                      class="m-r-10"
                      style="width:160px">
                <Option v-for="item in facilitySmallTypeArr"
                        :value="item.id"
                        :key="item.id">{{ item.name }}</Option>
              </Select> -->
            </template>
            <template v-if="currentTabIndex===0">
              <Select v-model="carAlarmType"
                      clearable
                      label-in-value
                      placeholder="报警类型"
                      class="m-r-10"
                      style="width:160px">
                <Option v-for="item in carAlarmTypeArr"
                        :value="item.id"
                        :key="item.id">{{ item.name }}</Option>
              </Select>
              <MyTreeSelect :value="carNumber"
                            placeholder="车牌号"
                            clearable
                            class="m-r-10"
                            style="width:160px"
                            :data="carTreeArr"
                            search
                            checked
                            @onCheckChange="onChangeCarNumber">
              </MyTreeSelect>
            </template>
            <template v-if="currentTabIndex===1">
              <Select v-model="personAlarmType"
                      clearable
                      label-in-value
                      placeholder="报警类型"
                      class="m-r-10"
                      style="width:160px">
                <Option v-for="(item,index) in personAlarmTypeArr"
                        :value="item.id"
                        :key="index">{{ item.name }}</Option>
              </Select>
              <MyTreeSelect :value="personName"
                            placeholder="人员名称"
                            clearable
                            class="m-r-10"
                            style="width:160px"
                            :data="personTreeArr"
                            search
                            checked
                            @onCheckChange="onChangeName">
              </MyTreeSelect>
            </template>
            <template v-if="currentTabIndex===2">
              <Select v-model="faclAlarmType"
                      clearable
                      label-in-value
                      placeholder="报警类型"
                      class="m-r-10"
                      style="width:160px"
                      @on-change="onChangeFaclAlarmType">
                <Option v-for="item in faclAlarmTypeArr"
                        :value="item.id"
                        :key="item.id">{{ item.name }}</Option>
              </Select>
              <MyTreeSelect :value="facilityName"
                            placeholder="设施名称"
                            clearable
                            class="m-r-10"
                            style="width:160px"
                            :data="facilityArr"
                            search
                            checked
                            @onCheckChange="onChangeFacilityName">
              </MyTreeSelect>
            </template>
            <DatePicker :value="date"
                        type="daterange"
                        :start-date="new Date()"
                        placeholder="请选择时间范围"
                        @on-change="onChangeDate"
                        style="width:260px"></DatePicker>
          </div>
          <div class="flex">
            <Button type="primary"
                    class="m-l-10"
                    v-if="checkPermi(['/admin/runAlarm/query'])"
                    :ghost="buttonIndex == 0 ? false : true"
                    @click.stop="onClickCurrentMonth">本月</Button>
            <Button type="primary"
                    class="m-l-10"
                    v-if="checkPermi(['/admin/runAlarm/query'])"
                    :ghost="buttonIndex == 1 ? false : true"
                    @click.stop="onClickLastMonth">上月</Button>
            <Button type="primary"
                    class="m-l-10"
                    v-if="checkPermi(['/admin/runAlarm/query'])"
                    :ghost="buttonIndex == 2 ? false : true"
                    @click.stop="onClickCurrentWeek">本周</Button>
            <Button type="primary"
                    class="m-l-10"
                    v-if="checkPermi(['/admin/runAlarm/query'])"
                    :ghost="buttonIndex == 3 ? false : true"
                    @click.stop="onClickNearlyThreeDay">近三天</Button>
            <Button type="primary"
                    class="m-l-10"
                    v-if="checkPermi(['/admin/runAlarm/query'])"
                    @click.stop="onClickSearch">
              <Icon custom="i-icon icon-sousuo"
                    size="16"></Icon>
              查询
            </Button>
            <Button class="m-l-10"
                    v-if="checkPermi(['/admin/runAlarm/query'])"
                    @click.stop="onClickReset">
              <Icon custom="i-icon icon-shuaxin"
                    size="16"></Icon>
              重置
            </Button>
          </div>
        </div>
      </div>
    </div>
    <div class="content">
      <div class="flex">
        <Dropdown v-if="checkPermi(['/admin/runAlarm/export'])">
          <Button class="m-r-10">
            <Icon custom="i-icon icon-shangchuan"
                  size="16"></Icon>
            导出
          </Button>
          <DropdownMenu slot="list">
            <DropdownItem name="part">
              <ExportTemplate :path="getExportPath"
                              :data="getExportData('part')">
                <Button>
                  导出部分
                </Button>
              </ExportTemplate>
            </DropdownItem>
            <DropdownItem name="now">
              <ExportTemplate :path="getExportPath"
                              :data="getExportData('now')">
                <Button>
                  导出本页
                </Button>
              </ExportTemplate>
            </DropdownItem>
            <DropdownItem name="all">
              <ExportTemplate :path="getExportPath"
                              :data="getExportData('all')">
                <Button>
                  导出全部
                </Button>
              </ExportTemplate>
            </DropdownItem>
          </DropdownMenu>
        </Dropdown>
        <Dropdown @on-click="onClickBatch"
                  v-if="checkPermi(['/admin/runAlarm/edit'])">
          <Button class="m-r-10">
            <Icon custom="i-icon icon-xiugai1"
                  size="16"></Icon>
            批量操作
          </Button>
          <DropdownMenu slot="list">
            <DropdownItem name="handle"
                          v-if="checkPermi(['/admin/runAlarm/edit'])">批量处理</DropdownItem>
          </DropdownMenu>
        </Dropdown>
      </div>
      <div class="table">
        <Table ref="table"
               :height="tableHeight"
               :columns="getColumns"
               :data="data"
               border
               stripe
               :loading="tableLoading"
               :row-class-name="rowClassName"
               @on-selection-change="onChangeSelect"
               @on-sort-change="onChangeSort">
          <template slot-scope="{ row }"
                    slot="operation">
            <Button type="text"
                    class="tableOperaBtn"
                    v-hasPermi="['/admin/runAlarm/edit']"
                    @click.stop="onClickDeal(row)">处理</Button>
          </template>
        </Table>
        <Page class="m-t-10 m-l-10"
              :total="totalPage"
              :current="pageNum"
              :page-size="pageSize"
              show-total
              show-sizer
              show-elevator
              @on-change="onPageChange"
              @on-page-size-change="onPageSizeChange" />
      </div>
    </div>
    <!-- 处理 -->
    <ViolationAlarmDealModal v-model="violationAlarmDealVisible"
                             :dataId.sync="violationAlarmDealId"
                             :type="violationAlarmDealType"
                             @onClickConfirm="getList"
                             @onChange='onChangClearRowStyle'></ViolationAlarmDealModal>

    <!-- 处理记录 -->
    <ViolationAlarmDealListModal v-model="violationAlarmDealListVisible"
                                 :dataId.sync="violationAlarmDealListId"
                                 type="alarm"
                                 @onChange='onChangClearRowStyle'></ViolationAlarmDealListModal>

    <!-- 查看地址 -->
    <PositionDetailModal v-model="positionDetailVisible"
                         :address="positionAddress"
                         :lng="positionLng"
                         :lat="positionLat"
                         @onChange='onChangClearRowStyle'></PositionDetailModal>
  </div>
</template>

<script>
import { getCurrentMonth, getCurrentWeek, getNearlyThreeDay, getLastMonth, } from '@/utils/dateRangUtil'
import MyTreeSelect from '@/components/common/MyTreeSelect'
import ViolationAlarmDealModal from '@/components/product/admin/modal/patrolMaintain/ViolationAlarmDealModal'
import ViolationAlarmDealListModal from '@/components/product/admin/modal/patrolMaintain/ViolationAlarmDealListModal'
import PositionDetailModal from '@/components/product/main/template/PositionDetailModal';
import { mapActions, mapGetters } from 'vuex'
import ExportTemplate from '@/components/common/ExportTemplate'
import { checkPermi } from '@/utils/permission'
export default {
  components: {
    MyTreeSelect,
    ViolationAlarmDealModal,
    ViolationAlarmDealListModal,
    PositionDetailModal,
    ExportTemplate
  },
  data () {
    return {
      currentTabIndex: 0,
      buttonIndex: null,
      tabArr: [
        {
          name: '车辆报警'
        },
        {
          name: '人员报警'
        },
        // {
        //   name: '垃圾桶报警'
        // },
        {
          name: '设施报警'
        },
      ],
      alarmId: '',
      facilityBigType: '',
      facilityBigTypeArr: [],
      facilitySmallType: '',
      facilitySmallTypeArr: [],
      carNumber: '',
      carNumberId: '',
      personId: '',
      personName: '',
      facilityName: '',
      facilityId: '',
      facilityArr: [],
      carAlarmType: '',
      carAlarmTypeArr: [],
      personAlarmType: '',
      personAlarmTypeArr: [],
      faclAlarmType: '',
      faclAlarmTypeName: '',
      faclAlarmTypeArr: [],
      date: [],
      totalPage: 0,
      pageNum: 1,
      pageSize: 20,
      tableHeight: 0,
      tableLoading: false,
      columnsCar: [
        {
          type: 'selection',
          width: 45,
          align: 'center',
          fixed: 'left',
        },
        {
          title: '序号',
          width: 45,
          align: 'center',
          fixed: 'left',
          render: (h, params) => {
            let index = params.index + 1
            let num = (this.pageNum - 1) * this.pageSize + index
            return h('span', num)
          }
        },
        {
          title: '车牌号',
          key: 'carName',
          tooltip: true,
          width: 120,
          align: 'center',
          fixed: 'left',
        },
        {
          title: '所属机构',
          key: 'depName',
          tooltip: true,
          minWidth: 120,
          align: 'center'
        },
        {
          title: '报警类型',
          key: 'alarmTypeName',
          tooltip: true,
          width: 90,
          align: 'center'
        },
        {
          title: '发生地点',
          key: 'managePhone',
          tooltip: true,
          width: 100,
          align: 'center',
          render: (h, params) => {
            let row = params.row
            return h('span', {
              style: {
                color: '#25bb96',
                cursor: 'pointer'
              },
              on: {
                click: () => {
                  this.onClickAddress(row)
                }
              }
            }, '点击查看');
          }
        },
        {
          title: '报警时间',
          key: 'alarmTime',
          tooltip: true,
          width: 180,
          align: 'center'
        },
        {
          title: '内容',
          key: 'content',
          tooltip: true,
          minWidth: 200,
          align: 'center'
        },
        {
          title: '处理记录',
          key: 'dealType',
          tooltip: true,
          width: 80,
          align: 'center',
          render: (h, params) => {
            let row = params.row
            return h('span', {
              style: {
                color: '#25bb96',
                cursor: 'pointer'
              },
              on: {
                click: () => {
                  this.processingRecord(row)
                }
              }
            }, '点击查看');
          }
        },
        {
          title: '处理方式',
          key: 'dealType',
          tooltip: true,
          width: 80,
          align: 'center',
          render: (h, params) => {
            let row = params.row
            return h('span', row.dealType ? row.dealType : '待处理');
          }
        },
        {
          title: '备注',
          key: 'dealRemark',
          tooltip: true,
          minWidth: 120,
          align: 'center'
        },
        {
          title: '操作',
          slot: 'operation',
          width: 80,
          align: 'center'
        }
      ],
      columnsPerson: [
        {
          type: 'selection',
          width: 45,
          align: 'center',
          fixed: 'left',
        },
        {
          title: '序号',
          width: 45,
          align: 'center',
          fixed: 'left',
          render: (h, params) => {
            let index = params.index + 1
            let num = (this.pageNum - 1) * this.pageSize + index
            return h('span', num)
          }
        },
        {
          title: '人员名称',
          key: 'personName',
          tooltip: true,
          width: 120,
          align: 'center',
          fixed: 'left',
        },
        {
          title: '所属机构',
          key: 'depName',
          tooltip: true,
          minWidth: 120,
          align: 'center'
        },
        {
          title: '报警类型',
          key: 'alarmTypeName',
          tooltip: true,
          width: 90,
          align: 'center'
        },
        {
          title: '发生地点',
          key: 'managePhone',
          tooltip: true,
          width: 100,
          align: 'center',
          render: (h, params) => {
            let row = params.row
            return h('span', {
              style: {
                color: '#25bb96',
                cursor: 'pointer'
              },
              on: {
                click: () => {
                  this.onClickAddress(row)
                }
              }
            }, '点击查看');
          }
        },
        {
          title: '报警时间',
          key: 'alarmTime',
          tooltip: true,
          width: 180,
          align: 'center'
        },
        {
          title: '内容',
          key: 'content',
          tooltip: true,
          minWidth: 200,
          align: 'center'
        },
        {
          title: '处理记录',
          key: 'dealType',
          tooltip: true,
          width: 80,
          align: 'center',
          render: (h, params) => {
            let row = params.row
            return h('span', {
              style: {
                color: '#25bb96',
                cursor: 'pointer'
              },
              on: {
                click: () => {
                  this.processingRecord(row)
                }
              }
            }, '点击查看');
          }
        },
        {
          title: '处理方式',
          key: 'dealType',
          tooltip: true,
          width: 80,
          align: 'center',
          render: (h, params) => {
            let row = params.row
            return h('span', row.dealType ? row.dealType : '待处理');
          }
        },
        {
          title: '备注',
          key: 'dealRemark',
          tooltip: true,
          minWidth: 120,
          align: 'center'
        },
        {
          title: '操作',
          slot: 'operation',
          width: 80,
          align: 'center'
        }
      ],
      columnsFacility: [
        {
          type: 'selection',
          width: 45,
          align: 'center',
          fixed: 'left',
        },
        {
          title: '序号',
          width: 45,
          align: 'center',
          fixed: 'left',
          render: (h, params) => {
            let index = params.index + 1
            let num = (this.pageNum - 1) * this.pageSize + index
            return h('span', num)
          }
        },
        {
          title: '设施名称',
          key: 'sheshiName',
          tooltip: true,
          width: 120,
          align: 'center',
          fixed: 'left',
        },
        {
          title: '所属机构',
          key: 'depName',
          tooltip: true,
          minWidth: 120,
          align: 'center'
        },
        {
          title: '报警类型',
          key: 'alarmTypeName',
          tooltip: true,
          width: 90,
          align: 'center'
        },
        {
          title: '发生地点',
          key: 'managePhone',
          tooltip: true,
          width: 100,
          align: 'center',
          render: (h, params) => {
            let row = params.row
            return h('span', {
              style: {
                color: '#25bb96',
                cursor: 'pointer'
              },
              on: {
                click: () => {
                  this.onClickAddress(row)
                }
              }
            }, '点击查看');
          }
        },
        {
          title: '报警时间',
          key: 'alarmTime',
          tooltip: true,
          width: 180,
          align: 'center'
        },
        {
          title: '内容',
          key: 'content',
          tooltip: true,
          minWidth: 200,
          align: 'center'
        },
        {
          title: '处理记录',
          key: 'dealType',
          tooltip: true,
          width: 80,
          align: 'center',
          render: (h, params) => {
            let row = params.row
            return h('span', {
              style: {
                color: '#25bb96',
                cursor: 'pointer'
              },
              on: {
                click: () => {
                  this.processingRecord(row)
                }
              }
            }, '点击查看');
          }
        },
        {
          title: '处理方式',
          key: 'dealType',
          tooltip: true,
          width: 80,
          align: 'center',
          render: (h, params) => {
            let row = params.row
            return h('span', row.dealType ? row.dealType : '待处理');
          }
        },
        {
          title: '备注',
          key: 'dealRemark',
          tooltip: true,
          minWidth: 120,
          align: 'center'
        },
        {
          title: '操作',
          slot: 'operation',
          width: 80,
          align: 'center'
        }
      ],
      data: [],
      selectArr: [],
      selectDataArr: [],
      selectIndexArr: [],
      selectVisitedArr: [],
      selectVisitedIndexArr: [],
      // 处理
      violationAlarmDealVisible: false,
      violationAlarmDealId: '',
      violationAlarmDealType: '',
      // 处理记录
      violationAlarmDealListVisible: false,
      violationAlarmDealListId: '',
      // 查看地址
      positionDetailVisible: false,
      positionAddress: '',
      positionLng: 0,
      positionLat: 0
    };
  },
  watch: {
    currentTabIndex () {
      // this.resetSearchData()
      // this.onClickSearch()
    },
    selectDataArr (newVal) {
      this.selectIndexArr = []
      if (newVal.length > 0) {
        newVal.forEach(item => {
          if (this.currentTabIndex === 0) {
            let data = this.data.find((items, index) => {
              return item.alarmCarId == items.alarmCarId
            })
            this.selectIndexArr.push(data.alarmCarId)
          } else if (this.currentTabIndex === 1) {
            let data = this.data.find((items, index) => {
              return item.alarmPersonId == items.alarmPersonId
            })
            this.selectIndexArr.push(data.alarmPersonId)
          } else {
            let data = this.data.find((items, index) => {
              return item.alarmSheshiId == items.alarmSheshiId
            })
            this.selectIndexArr.push(data.alarmSheshiId)
          }
        })
      }
    },
    selectVisitedArr (newVal) {
      console.log(newVal);
      this.selectVisitedIndexArr = []
      if (newVal.length > 0) {
        newVal.forEach(item => {
          if (this.currentTabIndex === 0) {
            let data = this.data.find((items, index) => {
              return item.alarmCarId == items.alarmCarId
            })
            this.selectVisitedIndexArr.push(data.alarmCarId)
          } else if (this.currentTabIndex === 1) {
            let data = this.data.find((items, index) => {
              return item.alarmPersonId == items.alarmPersonId
            })
            this.selectVisitedIndexArr.push(data.alarmPersonId)
          } else {
            let data = this.data.find((items, index) => {
              return item.alarmSheshiId == items.alarmSheshiId
            })
            this.selectVisitedIndexArr.push(data.alarmSheshiId)
          }
        })
      }
    }
  },
  computed: {
    ...mapGetters({
      'carTreeArr': 'getCarTreeList',
      'personTreeArr': 'getPersonTreeList'
    }),
    getColumns () {
      let columns = []
      switch (this.currentTabIndex) {
        case 0:
          columns = this.columnsCar
          break;
        case 1:
          columns = this.columnsPerson
          break;
        case 2:
          columns = this.columnsFacility
          break;
        default:
          break;
      }
      return columns
    },
    getExportPath () {
      let path = ''
      switch (this.currentTabIndex) {
        case 0:
          path = this.$http.exportCarAlarm
          break;
        case 1:
          path = this.$http.exportPersonAlarm
          break;
        case 2:
          path = this.$http.exportFacilityAlarm
          break;
        default:
          break;
      }
      return path
    },
  },
  mounted () {
    this.init()
  },
  destroyed () {
    window.onresize = null
  },
  methods: {
    checkPermi,
    ...mapActions([
      'updateCarTreeList',
      'updatePersonTreeList',
    ]),
    init () {
      this.tableHeight = window.innerHeight - 350
      window.onresize = () => {
        this.tableHeight = window.innerHeight - 350
      }
      this.updateCarTreeList()
      this.updatePersonTreeList()
      this.getFacilityBigTypeList()
      this.onChangeBigType()
      this.getCarAlarmTypeList()
      this.getPersonAlarmTypeList()
      this.getFaclAlarmTypeList()
      // 判断跳转接收的参数
      if (this.$route.params.authorType) {
        let params = this.$route.params
        if (params.authorType === 'car') {
          this.alarmId = params.id
          this.carAlarmType = params.type
          this.carNumberId = params.authorId
          this.carNumber = params.author
        } else if (params.authorType === 'person') {
          this.currentTabIndex = 1
          this.alarmId = params.id
          this.personAlarmType = params.type
          this.personId = params.authorId
          this.personName = params.author
        } else {
          this.currentTabIndex = 2
          this.alarmId = params.id
          // this.faclAlarmType = params.typeId
          this.faclAlarmTypeName = params.type
          this.facilityId = params.authorId
          this.facilityName = params.author
        }
      }
      this.getList()
    },
    // 点击切换tab
    onClickTabs () {
      this.resetSearchData()
      this.onClickSearch()
    },
    // 获取设施大类下拉列表
    getFacilityBigTypeList () {
      this.$http.getFacilityBigTypeAllList({
        type: 'facl'
      }).then(res => {
        if (res.code === 200) {
          let arr = [{
            id: 0,
            name: '全部'
          }]
          this.facilityBigTypeArr = arr.concat(res.result)
        }
      })
    },
    // 设施大类回调
    onChangeBigType (value) {
      if (value) {
        let arr = [{
          id: 0,
          name: '全部'
        }]
        this.$http.getFacilitySmallTypeAllList({ facilitietypeid: value }).then(res => {
          if (res.code === 200) {
            this.facilitySmallTypeArr = arr.concat(res.result)
          }
        })
        this.$http.getBindObjectTreeList({ treetype: value }).then(res => {
          if (res.code === 200) {
            this.facilityArr = res.result
          }
        })
        this.getFaclAlarmTypeList(value)
      } else {
        this.facilitySmallTypeArr = []
        this.$http.getBindObjectTreeList({ treetype: 'facl' }).then(res => {
          if (res.code === 200) {
            this.facilityArr = res.result
          }
        })
        this.getFaclAlarmTypeList()
      }
      this.facilitySmallType = ''
      this.facilityName = ''
      this.facilityId = ''
    },
    // 选择车牌号
    onChangeCarNumber (section) {
      let idArr = []
      let valueArr = []
      section.forEach(item => {
        if (item.type === 'car') {
          idArr.push(item.id)
          valueArr.push(item.name)
        }
      })
      this.carNumberId = idArr.join()
      this.carNumber = valueArr.join()
    },
    // 选择姓名
    onChangeName (section) {
      let idArr = []
      let valueArr = []
      section.forEach(item => {
        if (item.type === 'user') {
          idArr.push(item.id)
          valueArr.push(item.name)
        }
      })
      this.personId = idArr.join()
      this.personName = valueArr.join()
    },
    // 选择设施
    onChangeFacilityName (section) {
      let idArr = []
      let valueArr = []
      section.forEach(item => {
        if (item.type !== 'depart') {
          idArr.push(item.id)
          valueArr.push(item.name)
        }
      })
      this.facilityId = idArr.join()
      this.facilityName = valueArr.join()
    },
    // 选择设施报警类型
    onChangeFaclAlarmType (data) {
      if (data) {
        this.faclAlarmType = data.value
        this.faclAlarmTypeName = data.label
      } else {
        this.faclAlarmType = ''
        this.faclAlarmTypeName = ''
      }
    },
    // 点击表格中的查看位置
    onClickAddress (row) {
      this.positionAddress = row.address
      this.positionLng = row.lng
      this.positionLat = row.lat
      this.positionDetailVisible = true
      this.selectVisitedArr.push(row)
    },
    // 近三天
    onClickNearlyThreeDay () {
      this.date = getNearlyThreeDay()
      this.onClickSearch()
      this.buttonIndex = 3
    },
    // 本周
    onClickCurrentWeek () {
      this.date = getCurrentWeek()
      this.onClickSearch()
      this.buttonIndex = 2
    },
    // 本月
    onClickCurrentMonth () {
      this.date = getCurrentMonth()
      this.onClickSearch()
      this.buttonIndex = 0
    },
    // 上月
    onClickLastMonth () {
      this.date = getLastMonth()
      this.onClickSearch()
      this.buttonIndex = 1
    },
    onClickSet () {

    },
    onChangeDate (date) {
      this.date = date
      this.buttonIndex = null
    },
    onClickBatch (name) {
      switch (name) {
        case 'handle':
          console.log('handle');
          this.onClickBatchHandle()
          break;
        default:
          break;
      }
    },
    onClickBatchHandle () {
      if (this.selectArr.length == 0) {
        this.$Message.info('请选择数据')
      } else {
        this.violationAlarmDealVisible = true
        this.violationAlarmDealId = this.selectArr.join()
        switch (this.currentTabIndex) {
          case 0:
            this.violationAlarmDealType = 'carAlarm'
            break;
          case 1:
            this.violationAlarmDealType = 'personAlarm'
            break;
          case 2:
            this.violationAlarmDealType = 'facilityAlarm'
            break;
          default:
            break;
        }
      }
    },
    onChangeSelect (selection) {
      this.selectDataArr = selection
      switch (this.currentTabIndex) {
        case 0:
          this.selectArr = selection.map(item => item.alarmCarId)
          break;
        case 1:
          this.selectArr = selection.map(item => item.alarmPersonId)
          break;
        case 2:
          this.selectArr = selection.map(item => item.alarmSheshiId)
          break;
        default:
          break;
      }
    },
    onChangeSort (column, key, order) {

    },
    rowClassName (row, index) {
      if (this.currentTabIndex === 0) {
        if (this.selectVisitedIndexArr.includes(row.alarmCarId)) {
          return 'table-visited-bgcolor'
        }
      } else if (this.currentTabIndex === 1) {
        if (this.selectVisitedIndexArr.includes(row.alarmPersonId)) {
          return 'table-visited-bgcolor'
        }
      } else {
        if (this.selectVisitedIndexArr.includes(row.alarmSheshiId)) {
          return 'table-visited-bgcolor'
        }
      }
      if (this.currentTabIndex === 0) {
        if (this.selectIndexArr.includes(row.alarmCarId)) {
          return 'table-bgcolor'
        }
      } else if (this.currentTabIndex === 1) {
        if (this.selectIndexArr.includes(row.alarmPersonId)) {
          return 'table-bgcolor'
        }
      } else {
        if (this.selectIndexArr.includes(row.alarmSheshiId)) {
          return 'table-bgcolor'
        }
      }

    },
    // 页数改变
    onPageChange (event) {
      this.pageNum = event
      this.getList()
    },
    // 每页条数改变
    onPageSizeChange (event) {
      this.pageSize = event
      this.getList()
    },
    // 查询
    onClickSearch () {
      this.pageNum = 1
      this.getList()
    },
    // 点击重置
    onClickReset () {
      this.resetSearchData()
      this.onClickSearch()
    },
    // 重置搜索条件
    resetSearchData () {
      this.alarmId = ''
      // 车辆
      this.carNumber = ''
      this.carNumberId = ''
      this.carAlarmType = ''

      // 人员
      this.personName = ''
      this.personId = ''
      this.personAlarmType = ''

      // 设施
      this.facilityName = ''
      this.facilityId = ''
      this.facilityBigType = ''
      this.facilitySmallType = ''
      this.faclAlarmType = ''
      this.faclAlarmTypeName = ''

      this.date = []
      this.buttonIndex = null
      // this.alarmTypeArr = []
      // this.getAlarmTypeList()
    },
    getList () {
      this.selectIndexArr = []
      switch (this.currentTabIndex) {
        case 0:
          this.getCarAlarmList()
          break;
        case 1:
          this.getPersonAlarmList()
          break;
        case 2:
          this.getFacilityAlarmList()
          break;
        default:
          break;
      }
    },
    // 获取车辆报警类型列表
    getCarAlarmTypeList () {
      let arr = [{
        id: 0,
        name: '全部'
      }]
      let params = {
        page: 1,
        size: 99999,
        thresholdtype: 'car'
      }
      this.$http.getAlarmList(params).then(res => {
        if (res.code === 200) {
          this.carAlarmTypeArr = arr.concat(res.result.thresholdlist.map(item => {
            item.name = item.sysname
            item.id = item.sysname
            return item
          }))
        }
      })
    },
    // 获取人员报警类型列表
    getPersonAlarmTypeList () {
      let arr = [{
        id: 0,
        name: '全部'
      }]
      let params = {
        page: 1,
        size: 99999,
        thresholdtype: 'person'
      }
      this.$http.getAlarmList(params).then(res => {
        if (res.code === 200) {
          this.personAlarmTypeArr = arr.concat(res.result.thresholdlist.map(item => {
            item.name = item.sysname
            item.id = item.sysname
            return item
          }))
        }
      })
    },
    // 获取设施报警类型列表
    getFaclAlarmTypeList (value) {
      let arr = [{
        id: 0,
        name: '全部'
      }]
      let params = {
        page: 1,
        size: 99999,
        thresholdtype: 'facl'
      }
      if (value) {
        params.faclitypeid = value
      } else {
        params.faclitypeid = ''
      }
      this.$http.getAlarmList(params).then(res => {
        if (res.code === 200) {
          this.faclAlarmTypeArr = arr.concat(res.result.thresholdlist.map(item => {
            item.name = item.sysname
            return item
          }))
        }
      })
    },
    // 车辆报警
    getCarAlarmList () {
      let params = {
        alarmCarId: this.alarmId,
        carId: this.carNumberId,
        // alarmTypeId: this.carAlarmType == 0 ? '' : this.carAlarmType,
        alarmTypeName: this.carAlarmType == 0 ? '' : this.carAlarmType,
        startDate: this.date.length == 0 ? '' : this.date[0],
        endDate: this.date.length == 0 ? '' : this.date[1],
        page: this.pageNum,
        size: this.pageSize
      }
      this.tableLoading = true
      this.$http.getCarRunAlarmList(params).then(res => {
        this.tableLoading = false
        if (res.code === 200) {
          this.data = res.result.list
          this.totalPage = res.result.total
        }
      }).catch(err => {
        this.tableLoading = false
      })
    },
    // 人员报警
    getPersonAlarmList () {
      let params = {
        alarmPersonId: this.alarmId,
        personId: this.personId,
        // alarmTypeId: this.personAlarmType == 0 ? '' : this.personAlarmType,
        alarmTypeName: this.personAlarmType == 0 ? '' : this.personAlarmType,
        startDate: this.date.length == 0 ? '' : this.date[0],
        endDate: this.date.length == 0 ? '' : this.date[1],
        page: this.pageNum,
        size: this.pageSize
      }
      this.tableLoading = true
      this.$http.getPersonAlarmList(params).then(res => {
        this.tableLoading = false
        if (res.code === 200) {
          this.data = res.result.list
          this.totalPage = res.result.total
        }
      }).catch(err => {
        this.tableLoading = false
      })
    },
    // 设施报警
    getFacilityAlarmList () {
      let params =
      {
        alarmSheshiId: this.alarmId,
        sheshiId: this.facilityId,
        sheshiName: this.facilityName,
        alarmTypeId: this.faclAlarmType == 0 ? '' : this.faclAlarmType,
        alarmTypeName: this.faclAlarmType == 0 ? '' : this.faclAlarmTypeName,
        startDate: this.date.length == 0 ? '' : this.date[0],
        endDate: this.date.length == 0 ? '' : this.date[1],
        facilitiesTypeId: this.facilityBigType ? this.facilityBigType : '',
        facilitiesTypeItemsId: this.facilitySmallType ? this.facilitySmallType : '',
        page: this.pageNum,
        size: this.pageSize
      }

      // {
      //   "alarmSheshiId": "b9aa7a13b550f9dd384d2b0018f2cbfa",
      //   "sheshiId": "a7a7799af9234ce594891df5658e878c",
      //   // "alarmTypeId": "a620f805dc3327c9bf75585e460ade6a",
      //   "sheshiName": "虞新街公厕",
      //   "alarmTypeName": "氨气报警",
      //   "startDate": "",
      //   "endDate": "",
      //   "facilitiesTypeId": "",
      //   "facilitiesTypeItemsId": "",
      //   "page": 1,
      //   "size": 20
      // }


      // address: "虞河路2832东北方向114米"
      // alarmEndTime: "2021-09-14 13:44:30"
      // alarmSheshiId: "b9aa7a13b550f9dd384d2b0018f2cbfa"
      // alarmStartTime: "2021-09-14 13:44:30"
      // alarmTime: "2021-09-14 13:44:30"
      // alarmTypeId: "9329694382b6492e8ec48ac01fac320c"
      // alarmTypeName: "氨气报警"
      // alarmValue: 49085
      // content: "氨气报警时长0秒，报警最高浓度为49085ppm"
      // createTime: "2021-09-14 13:44:30"
      // dealRemark: null
      // dealType: null
      // dealUid: null
      // depId: "05fc631946a1414e895c460be6f5be91"
      // depName: "智慧环卫演示版gs"
      // endDate: null
      // expType: null
      // facltypeid: "bf83a05e10e3d47cf24d2b304c29ac98"
      // facltypeitemid: "35e0f51f74cbfbad699d610a3033451c"
      // facltypeitemname: "其他"
      // facltypename: "公厕"
      // gasType: "ammonia"
      // isKeep: 0
      // lat: "36.7041"
      // lng: "119.12859"
      // sheshiId: "a7a7799af9234ce594891df5658e878c"
      // sheshiName: "虞新街公厕"
      // sheshiNameAndAlarmTypeName: null
      // startDate: null
      this.tableLoading = true
      this.$http.getFacilityAlarmList(params).then(res => {
        this.tableLoading = false
        if (res.code === 200) {
          this.data = res.result.list
          this.totalPage = res.result.total
        }
      }).catch(err => {
        this.tableLoading = false
      })
    },
    // 处理按钮
    onClickDeal (row) {
      this.selectVisitedArr.push(row)
      this.violationAlarmDealVisible = true
      if (this.currentTabIndex == 0) {
        this.violationAlarmDealId = row.alarmCarId
        this.violationAlarmDealType = 'carAlarm'
      } else if (this.currentTabIndex == 1) {
        this.violationAlarmDealId = row.alarmPersonId
        this.violationAlarmDealType = 'personAlarm'
      } else
        //  if (this.currentTabIndex == 2) {
        //   this.violationAlarmDealId = row.alarmAshbinId
        //   this.violationAlarmDealType = 'ashbinAlarm'
        // } else
        if (this.currentTabIndex == 2) {
          this.violationAlarmDealId = row.alarmSheshiId
          this.violationAlarmDealType = 'facilityAlarm'
        }
    },
    onChangClearRowStyle () {
      this.selectVisitedArr = []

    },
    // 导出文件数据
    getExportData (value) {
      let data = {}
      switch (this.currentTabIndex) {
        case 0:
          data = {
            alarmCarId: this.alarmId,
            carId: this.carNumberId,
            alarmTypeName: this.carAlarmType == 0 ? '' : this.carAlarmType,
            startDate: this.date.length == 0 ? '' : this.date[0],
            endDate: this.date.length == 0 ? '' : this.date[1],
          }
          if (value == 'now') {
            let ids = this.data.map(item => {
              return item.alarmCarId
            })
            data.ids = ids.join()
          } else if (value == 'part') {
            data.ids = this.selectArr
            data.ids = data.ids.join()
          } else {
            // data.ids = ''
          }
          break;
        case 1:
          data = {
            alarmPersonId: this.alarmId,
            personId: this.personId,
            alarmTypeName: this.personAlarmType == 0 ? '' : this.personAlarmType,
            startDate: this.date.length == 0 ? '' : this.date[0],
            endDate: this.date.length == 0 ? '' : this.date[1],
          }
          if (value == 'now') {
            let ids = this.data.map(item => {
              return item.alarmCarId
            })
            data.ids = ids.join()
          } else if (value == 'part') {
            data.ids = this.selectArr
            data.ids = data.ids.join()
          } else {
            // data.ids = ''
          }
          break;
        case 2:
          data = {
            alarmSheshiId: this.alarmId,
            sheshiId: this.facilityId,
            sheshiName: this.facilityName,
            alarmTypeId: this.faclAlarmType == 0 ? '' : this.faclAlarmType,
            alarmTypeName: this.faclAlarmType == 0 ? '' : this.faclAlarmTypeName,
            startDate: this.date.length == 0 ? '' : this.date[0],
            endDate: this.date.length == 0 ? '' : this.date[1],
            facilitiesTypeId: this.facilityBigType ? this.facilityBigType : '',
            facilitiesTypeItemsId: this.facilitySmallType ? this.facilitySmallType : '',
          }
          if (value == 'now') {
            let ids = this.data.map(item => {
              return item.alarmSheshiId
            })
            data.ids = ids.join()
          } else if (value == 'part') {
            data.ids = this.selectArr
            data.ids = data.ids.join()
          } else {
            // data.ids = ''
          }
          break;
        default:
          break;
      }
      return data
    },
    // 点击查看处理记录
    processingRecord (row) {
      this.selectVisitedArr.push(row)
      switch (this.currentTabIndex) {
        case 0:
          this.violationAlarmDealListId = row.alarmCarId
          break;
        case 1:
          this.violationAlarmDealListId = row.alarmPersonId
          break;
        case 2:
          this.violationAlarmDealListId = row.alarmSheshiId
          break;
        default:
          break;
      }
      this.violationAlarmDealListVisible = true
    }
  },
}
</script>

<style lang='scss' scoped>
@import "@/scss/iviewCssReset/adminTabs.scss";
::v-deep {
  .ivu-table .table-bgcolor td {
    background: #ebf7ff !important;
    border-right: 1px solid #ebf7ff;
  }
  .ivu-table .table-visited-bgcolor td {
    background: rgb(235, 247, 255) !important;
    border-right: 1px solid rgb(235, 247, 255);
  }
}
.main {
  width: 100%;
  height: 100%;
  padding: 16px;
  background-color: #f0f2f5;
  .topbar {
    background-color: #fff;
    .topbar-search {
      padding: 16px;
    }
  }
  .content {
    padding: 16px;
    background-color: #fff;
    margin-top: 16px;
    .table {
      margin-top: 16px;
    }
  }
}
</style>